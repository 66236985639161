import React, { useEffect, useState } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

// Define the props type
interface QueueBannerProps {
  position: number; // Current queue position
  total: number; // Total number of people in the queue
}

const QueueBanner: React.FC<QueueBannerProps> = ({ position, total }) => {
  // Optional: Use this state to manage animations or display changes
  const [currentPosition, setCurrentPosition] = useState(position);
  const [currentTotal, setCurrentTotal] = useState(total);
  const [progress, setProgress] = useState(0);


  // Watch for changes in the position prop
  useEffect(() => {
    if (position !== currentPosition) {
      setCurrentPosition(position);
      console.log(`Queue position updated: ${position}`);
    }
    if (total !== currentTotal) {
      setCurrentTotal(total);
      console.log(`Total queue size updated: ${total}`);
    }
  }, [position, total, currentPosition, currentTotal]);

    // Update progress dynamically when position or total changes
    useEffect(() => {
        if (currentTotal > 0) {
          const calculatedProgress = ((currentTotal - currentPosition + 1) / currentTotal) * 100;
          setProgress(calculatedProgress);
        }
      }, [currentPosition, currentTotal]);

  // Only show the banner if the queue exists and `total` is greater than 0
  if (currentPosition  ==0) {
    return null;
  }

    

  return (
    <Box
      sx={{
        background: "rgb(255, 193, 7)", // Crane machine theme color
        borderRadius: "20px", // Rounded corners
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.5)", // Arcade-style shadow
        padding: "20px",
        textAlign: "center",
        maxWidth: "400px",
        margin: "0 auto", // Center horizontally
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#000", // Black text for contrast
          textShadow: "2px 2px 4px rgba(255, 255, 255, 0.5)", // Subtle glow
          marginBottom: "16px",
        }}
      >
        Oh, shucks! Other people are playing.
      </Typography>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          color: "#000", // Black text
          marginBottom: "12px",
        }}
      >
        Your queue position:{" "}
        <Box
          component="span"
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "#fff", // Highlight queue position with white
            background: "#000", // Black background for emphasis
            padding: "2px 8px",
            borderRadius: "8px",
            display: "inline-block",
          }}
        >
          {currentPosition}
        </Box>
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "#000", // Black text
          marginBottom: "20px",
        }}
      >
        Please hold tight and the game will automatically start when it's your
        turn.
      </Typography>

</Box>

  );
};

export default QueueBanner;
