import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

// Props type definition
interface PlayLimitPopupProps {
  open: boolean; // Whether the popup is open
  onClose: () => void; // Function to close the popup
}

const PlayLimitPopup: React.FC<PlayLimitPopupProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          background: "rgb(255, 193, 7)", // Crane machine color
          border: "4px solid rgba(0, 0, 0, 0.2)", // Subtle border
          borderRadius: "20px", // Rounded corners
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.5)", // Pop-out shadow
          textAlign: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#000", // Black for contrast
          textShadow: "1px 1px 2px rgba(255, 255, 255, 0.5)", // Subtle glow
        }}
      >
        Oops!
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#000", // Black text
            margin: "10px 0",
          }}
        >
          Sorry, you've already played today. You can only play once per day.
          Try again tomorrow!
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "4px",
            background: "rgba(0, 0, 0, 0.2)", // Separator line
            margin: "20px 0",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            background: "rgb(121, 0, 255)", // Purple button color
            color: "#fff", // White text for contrast
            fontSize: "16px",
            fontWeight: "bold",
            textTransform: "uppercase",
            padding: "10px 20px",
            borderRadius: "30px",
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
            "&:hover": {
              background: "rgba(121, 0, 255, 0.9)", // Slightly lighter purple on hover
            },
          }}
        >
          Got It!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayLimitPopup;
