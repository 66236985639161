import React, { useRef, useState, useEffect } from "react";

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { IconButton } from "@mui/material";

interface AudioToggleButtonProps{
  isFirstGameStarted: boolean;
  isAudioEnabled: boolean; // Current audio state
  onAudioToggle: (value: boolean) => void; // Function to propagate state up
}

const AudioToggleButton: React.FC<AudioToggleButtonProps> = ({isFirstGameStarted, isAudioEnabled, onAudioToggle}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null); // Ref for the audio element

  const handleToggle = () => {
    onAudioToggle(!isAudioEnabled); // Toggle and propagate the new value
  };


  
  useEffect(() => {
    if (audioRef.current) {
      if (isFirstGameStarted) {
        console.log('first game started!')
        onAudioToggle(!isAudioEnabled)
        toggleAudio()
        console.log('play sound effect')
      } else {
        audioRef.current.pause()
      }
    }
  }, [isFirstGameStarted]); // React to changes in the isPlaying prop

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isAudioEnabled) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      handleToggle()
      //setIsPlaying(!isPlaying);
    }
  };

  return (
    <div>
      {/* Hidden audio element */}
      {/*<audio ref={audioRef} src={"music/level-vii.mp3"} autoPlay loop hidden />*/}
      {/*<audio ref={audioRef} src={"music/christmas.mp3"} autoPlay loop hidden />*/}
      <audio ref={audioRef} src={"music/level-vii.mp3"} autoPlay loop hidden />
      
      {/* Toggle button */}
      <IconButton
        onClick={toggleAudio}
        size="large"
        style={{
            color:'black',
            fontSize: "36px",
          cursor: "pointer",
        }}
        aria-label={isAudioEnabled? "Pause music" : "Play music"}
      >
        {isAudioEnabled? 
        
        <VolumeUpIcon/> : 
        
        <VolumeOffIcon/>}
</IconButton>
    </div>
  );
};

export default AudioToggleButton;
